const ar = {
  translation: {
    selectLanguage: "اللغة المختارة",
    saveButton: "يحفظ",
    footerText:
      "يالا هي منصة إدارة توصيل مفتوحة المصدر للمستقبل. نحن نولي اهتمامًا كبيرًا للابتكار والمرونة والتكلفة المنخفضة، ونقدم حلا قابلا للتوسيع وقابل للتخصيص يسهل عمليات التوصيل الخاصة بك.",
    footerLinkHome: "الصفحة الرئيسية",
    footerLinkPP: "سياسة الخصوصية",
    footerLinkTC: "الشروط والأحكام",
    footerEndText: "إناتيغا - © 2022 جميع الحقوق محفوظة",
    followUs: "تابعنا",
    poweredBy: "يعمل بواسطة",
    linksTitle: "روابط",
    subTotal: "المجموع الفرعي",
    deliveryFee: "رسوم التوصيل",
    haveVoucher: "هل لديك قسيمة ؟",
    remove: "إزالة",
    change: "يتغيرون",
    tip: "نصيحة",
    taxFee: "الرسوم الضريبية",
    total: "مجموع",
    discount: "خصم",
    name: "اسم",
    phone: "هاتف",
    email: "بريد إلكتروني",
    address: "عنوان",
    changeAddress: "تغيير العنوان",
    contactInfo: "معلومات الاتصال",
    paymentMethod: "طريقة الدفع او السداد",
    orderBtn: "مكان الامر",
    deliveryTime: "وقت التوصيل",
    delivery: "توصيل",
    searchRestaurantPlaceholder: "البحث عن المطاعم والأطباق",
    allRestaurant: "جميع المطاعم",
    pending: "قيد الانتظار",
    accepted: "تم قبوله",
    assigned: "تم تعيينه",
    picked: "تم الاختيار",
    delivered: "تم التوصيل",
    completed: "تم الانتهاء",
    titleOrders: "طلباتي",
    titleProfile: "الملف الشخصي",
    titleSettings: "الإعدادات",
    titleHelp: "مساعدة",
    titleChat: "محادثة",
    titleLogout: "تسجيل خروج",
    passwordInfo: "معلومات كلمة المرور",
    myAddresses: "عناويني",
    edit: "تعديل",
    creditDebitCard: "بطاقة الائتمان / الخصم",
    paypal: "باي بال",
    cash: "نقداً",
    deliverTo: "توصيل إلى",
    ASSIGNED: "مُكَلَّف",
    PENDING: "قيد الانتظار",
    PICKED: "اختار",
    ACCEPTED: "قبلت",
    DELIVERED: "تم التوصيل",
    COMPLETED: "مكتمل",
    orderPlaced: "تم الطلب",
    "Order ID": "رقم الأمر",
    "Order status: PENDING": "حالة الطلب: في انتظار",
    "Order status: ACCEPTED": "حالة الطلب: مقبول",
    "Order status: ASSIGNED": "حالة الطلب: مُعيَّن",
    "Order status: PICKED": "حالة الطلب: تم ​​اختياره",
    "Order status: DELIVERED": "حالة الطلب: تم ​​التسليم",
    "Order status: Order Placed": "حالة الطلب: تم ​​تقديم الطلب",
    deliveryAddress: "عنوان التسليم",
    orderDetail: "تفاصيل الطلب",
    orderFrom: "طلبك من",
    orderNo: "رقم طلبك",
    findRestaurants: "العثور على المطاعم",
    putUsInYourPocket: "ضعنا في جيبك.",
    containerText:
      "كلها بين يديك - المطاعم التي تحبها. ابحث عن الطعام المناسب لمزاجك واجعل اللقمة الأولى تدوم. تفضل، قم بتنزيلنا.",
    findingAddress:
      "العثور على العنوان باستخدام دمج نظام تحديد المواقع العالمي (GPS)",
    zonesFunctionality: "وظيفة المناطق للمطاعم والمندوبين",
    multipleRestaurants: "ميزة إضافة مطاعم متعددة",
    realTimeOrder: "تحديثات استلام الطلبات في الوقت الحقيقي",
    differentSections: "ميزة الأقسام المختلفة للترويج للمطاعم",
    previousOrder: "تاريخ الطلبات السابقة وإضافة المطاعم المفضلة",
    ourDelivery: "نظام إدارة التوصيل الخاص بنا مصمم للمستقبل",
    builtOnCommunity: "بنيت على مبادئ توجيه المجتمع",
    yourCart: "سلة التسوق الخاصة بك",
    startAdding: "ابدأ في إضافة العناصر إلى سلة التسوق الخاصة بك",
    goToCheckout: "انتقل إلى السداد",
    activeOrders: "الطلبات النشطة",
    pastOrders: "الطلبات السابقة",
    deliveringTo: "توصيل إلى",
    restaurantDeliver: "نحن نسأل المطعم كم سيستغرق توصيل طعامك",
    riderDeliver: "نحن نسأل السائق كم سيستغرق توصيل طعامك",
    orderPending: "طلبك قيد الانتظار لا يزال",
    areYouSure: "هل أنت متأكد؟",
    clearCartText: "بتغيير المطعم، سيتم مسح العناصر التي أضفتها إلى السلة",
    ok: "حسنًا",
    cancel: "إلغاء",
    submit: "إرسال",
    titleFavourite: "المفضلة",
    titleEmptyFav: "لا توجد المفضلة المحفوظة",
    emptyFavDesc: "سترى كل ما تفضله هنا ، لجعل الطلب أسرع",
    emptyFavBtn: "دعونا نجد بعض المفضلة",
    exactLocation: "هل هذا موقعك الدقيق؟",
    yourArea: "أدخل منطقتك",
    currentLocation: "استخدم الموقع الحالي",
    deliveryDetails: "التفاصيل المطلوبة - على سبيل المثال. الطابق / المبنى",
    deliveryDetailError: "مطلوب تفاصيل التوصيل",
    currentPassword: "كلمة المرور الحالية",
    newPassword: "كلمة مرور جديدة",
    deliver: "توصيل",
    time: "وقت",
    pickup: "الاستلام",
    selectTime: "اختر الوقت",
    am: "صباحًا",
    pm: "مساءً",
    set: "تعيين",
    apply: "تطبيق",
    applyAVoucher: "تطبيق قسيمة",
    readAll: "اقرأ الكل",
    readMore: "اقرأ المزيد",
    demo: "عرض توضيحي",
    getQuote: "الحصول على اقتباس",
    restaurantCloseText:
      "المطعم مغلق الآن. تحقق من المطاعم الأخرى المفتوحة أو شاهد القائمة للتخطيط لوجبتك القادمة.",
    seeOtherRestaurants: "انظر لمطاعم أخرى",
    goToMenu: "الانتقال إلى القائمة",
    deliveryHours: "ساعات التوصيل",
    about: "حول",
    reviews: "التقييمات",
    closedAllDay: "مغلق طوال اليوم",
    minute: "دقيقة",
    welcome: "مرحبًا!",
    signUpOrLogin: "قم بالتسجيل أو تسجيل الدخول للمتابعة",
    signInWithGoogle: "المتابعة باستخدام Google",
    or: "أو",
    continueWithEmail: "المتابعة باستخدام البريد الإلكتروني",
    terms: "الشروط والأحكام",
    privacyPolicy: "سياسة الخصوصية",
    bySigningUp: "بالتسجيل، أنت توافق على ",
    and: " و ",
    whatsYourEmail: "ما هو بريدك الإلكتروني؟",
    checkAccount: "سنتحقق مما إذا كان لديك حساب",
    continue: "استمر",
    invalidEmail: "بريد إلكتروني غير صالح",
    letsGetStarted: "لنبدأ!",
    createAccount: "أنشئ حسابك في إناتيجا",
    firstnameErr1: "الاسم الأول مطلوب!",
    firstnameErr2: "اسم أول غير صالح!",
    lastnameErr1: "الاسم الأخير مطلوب!",
    lastnameErr2: "اسم أخير غير صالح!",
    mobileErr1: "رقم الهاتف مطلوب!",
    mobileErr2: "رقم هاتف غير صالح!",
    emailErr2: "بريد إلكتروني غير صالح",
    passwordErr1:
      "كلمة مرور غير صالحة. يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورقم واحد على الأقل.",
    passwordErr2: "كلمة مرور غير صالحة",
    generalErr: "هناك شيء ناقص",
    verifyEmail: "تحقق من بريدك الإلكتروني",
    enterOtp: "الرجاء إدخال رمز OTP الذي أرسلناه إلى بريدك الإلكتروني المحدث",
    enterOtpPhone: "الرجاء إدخال رمز OTP الذي أرسلناه إلى رقم هاتفك الإلكتروني",
    invalidCode: "الرمز غير صالح، الرجاء التحقق وإدخاله مرة أخرى",
    resendCode: "إعادة إرسال الرمز",
    retryAfter: "إعادة المحاولة بعد",
    loginBtn: "تسجيل الدخول",
    addToCart: "أضف إلى السلة",
    customize: "تخصيص",
    optional: "اختياري",
    required: "مطلوب",
    specialInstructions: "تعليمات خاصة",
    anySpecific: "هل لديك تفضيلات معينة؟ أخبر المطعم.",
    selectVariation: "اختر التباين",
    closed: "مغلق",
    new: "جديد",
    orderCancelled: "تم إلغاء طلبك",
    orderCompleted: "تم إكمال الطلب بنجاح. شكرًا لك على طلبك",
    reorder: "إعادة الطلب",
    review: "مراجعة",
    subTitle: " اختر ١",
    placeholder: "على سبيل المثال، بدون مايونيز",
    phoneNumMissing: "الرقم الهاتفي مفقود",
    updatePhone: "قم بتحديث هاتفك",
    number: "الرقم؟",
    secureAcc: "نحتاج هذا لتأمين حسابك",
    verifyPhone: "تحقق من هاتفك",
    skipNow: "تخطى الآن",
    favouriteListUpdated: "تم تحديث قائمة المفضلات",
    pendingText: "في انتظار الرد من",
    acceptedText: "الوقت المقدر للتحضير",
    orderLateText: "آسف! طلبك متأخر قليلاً.",
    riderPickText: "سيقوم سائقك بالحصول عليه عندما يكون جاهزًا.",
    orderIs: "طلبك هو",
    orderAssigned: "تم تعيينه للسائق",
    orderAssignedToRider:
      "تم تعيين طلبك لسائقنا، سيتم استلام الطلب عندما يكون جاهزًا.",
    riderOnWay: "سائقك في الطريق.",
    orderHasBeen: "تم طلبك",
    enjoyYourMeal: "استمتع بوجبتك!",
    cancelled: "تم الإلغاء",
    personalDetail: "التفاصيل الشخصية",
    voucherCode: "رمز القسيمة",
    pickUp: "استلام",
    warning: "تحذير",
    warningText: "أحد العناصر غير متاح",
    "Rider App": 'تطبيق السائق',
    FEATURES: 'الميزات',
    "Restaurant App": "تطبيق المطعم",
    "Customer App": 'تطبيق العميل',
    "Admin Dashboard": 'لوحة تحكم المسؤول',
    "Product Page": "صفحة المنتج",
    "PlayStore": 'متجر بلاي',
    "IosStore": "متجر آبل",
    APP: 'التطبيق',
    Yalla: 'يالا'
  },
};
export default ar;
